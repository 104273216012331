import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import AppLoader from "../../components/Helpers/AppLoader";

import { Tab } from "@headlessui/react";
import SettingsTextInput from "../../components/Forms/SettingsTextInput";
import SubmitButton from "../../components/Forms/SubmitButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faCirclePlus, faPenCircle, faTrashCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import SettingsSelect from "../../components/Forms/SettingsSelect";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
// import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";
// import SettingsSwitch from "../../components/Forms/SettingsSwitch";
import PhoneInput from "react-phone-number-input";
import SettingsDatePicker from "../../components/Forms/SettingsDatePicker";

const navigation = [
    { name: "Signatory Details" },
    { name: "Company Info" },
    { name: "Premises Info" },
    { name: "Suretyship Owner Details" },
    { name: "Leasing Info" },
    { name: "Audit Log" },
    { name: "Notes" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function EditUserQP() {
    const dispatch = useDispatch();
    const { user_id } = useParams();
    const { booking_id } = useParams();
    const { current_staff } = useSelector((state) => state.auth);
    // const app = initializeApp(firebaseConfig);
    // const db = getFirestore(app);

    // const [user, setUser] = useState([]);
    const [current_booking, setCurrentBooking] = useState([]);
    const [url, setUrl] = useState("");
    const [log, setLog] = useState({});
    const [notes, setNotes] = useState({});
    const { properties } = useSelector((state) => state.properties);
    const { settings } = useSelector((state) => state.settings);

    const [units, setUnits] = useState([]);
    // const [unitTypes, setUnitTypes] = useState([]);
    const [profileInitialValues, setProfileInitialValues] = useState({});
    // const [jointInitialValues, setJointInitialValues] = useState({});
    const [companyInitialValues, setCompanyInitialValues] = useState({});
    // const [bookingInitialValues, setBookingInitialValues] = useState({});
    const [premisesInitialValues, setPremisesInitialValues] = useState({});

    const [suretyInitialValues, setSuretyInitialValues] = useState({});
    // const [nextOfKinInitialValues, setNextOfKinInitialValues] = useState({});
    const [leasingInitialValues, setLeasingInitialValues] = useState({});
    // const [affordabilityCalculatorInitialValues, setAffordabilityCalculatorInitialValues] = useState({});

    const [loading, setLoading] = useState(true);
    const [auditloading, setAuditLoading] = useState(false);
    const [notesLoading, setNotesLoading] = useState(false);

    const [bankList, setBankList] = useState([]);
    const [selectedLeaseTerm, setSelectedLeaseTerm] = useState("");

    const history = useHistory();
    const [allowClick, setAllowClick] = useState(true);

    const leaseTerms = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi Lease" },
        { value: 2, label: "1 Year" },
        { value: 3, label: "2 Years" },
        { value: 4, label: "3 Years" },
        { value: 5, label: "4 Years" },
        { value: 6, label: "5+ Years" },
    ];

    const landContrib = [
        { value: 0, label: "" },
        { value: 1, label: "Beneficial Occupation" },
        { value: 2, label: "Tenant Installation" },
    ];

    const flexiList = [
        { value: 0, label: "" },
        { value: 1, label: "Month to Month" },
        { value: 2, label: "1 Month" },
        { value: 3, label: "2 Months" },
        { value: 4, label: "3 Months" },
        { value: 5, label: "4 Months" },
        { value: 6, label: "5 Months" },
        { value: 7, label: "6 Months" },
        { value: 8, label: "7 Months" },
        { value: 9, label: "8 Months" },
        { value: 10, label: "9 Months" },
        { value: 11, label: "10 Months" },
        { value: 12, label: "11 Months" },
    ];

    const genders = [
        { label: "Choose an option", value: 0 },
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
    ];

    const mStatus = [
        { label: "", value: 0 },
        { label: "Single", value: 1 },
        { label: "Married", value: 2 },
        { label: "Widowed", value: 3 },
        { label: "Divorced", value: 4 },
    ];

    const depositList = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi / 1 Year Lease = 1 Month Rent" },
        { value: 2, label: "2+ Years Lease = 2 Months Exit Rent" },
    ];

    useEffect(async () => {
        getUser();

        if (current_booking) {
            fetchNotes();
        }

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (history.action == "POP") {
            if (allowClick === true) {
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}channels`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}tagsList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}collectionList`,
                });
                axios({
                    method: "get",
                    url: `${process.env.REACT_APP_API_URL}mentionList/${current_staff.id}`,
                });

                if (settings && settings.brokers === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}brokers`,
                    });
                }

                if (settings && settings.renewals === 1) {
                    axios({
                        method: "get",
                        url: `${process.env.REACT_APP_API_URL}renewalsItem`,
                    });
                }

                setAllowClick(false);
            }
        }
    }, []);

    async function contributions(id) {
        setSelectedLeaseTerm(id);
    }

    async function getUser() {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}booking_user_id/${user_id}/${booking_id}`,
        }).then((response) => {
            const data = response.data;

            fetchAudit();
            // setUser(response.data);

            setCurrentBooking(data);

            if (data.property_id) {
                getUnits(data.property_id);
            }

            determineInitialValues(data);
            setLoading(false);
        });
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function availableIncome(income, outcome) {
        var money_in = formatToFloat(income);
        var money_out = formatToFloat(outcome);

        return money_in - money_out;
    }

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    async function determineInitialValues(data) {
        setUrl(data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url);

        setProfileInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            name: data.user.name,
            surname: data.user.surname,
            email: data.user.email,
            cell: "+" + data.user.cell,
            home_number: data.user.profile && data.user.profile.home_number ? "+" + data.user.profile.home_number : "",
            work_number: data.user.profile && data.user.profile.work_number ? "+" + data.user.profile.work_number : "",
            fax_number: data.user.profile && data.user.profile.fax_number ? "+" + data.user.profile.fax_number : "",
            nationality: data.user.profile ? data.user.profile.nationality : "",
            id_number: data.user.profile ? data.user.profile.id_number : "",
            date_of_birth: data.user.profile ? data.user.profile.date_of_birth : "",
            gender: data.user.profile ? data.user.profile.gender : 0,
            marital_status: data.user.profile ? data.user.profile.marital_status : 0,
            current_address: data.user.premises ? data.user.premises.current_address : "",
            current_suburb: data.user.premises ? data.user.premises.current_suburb : "",
            current_city: data.user.premises ? data.user.premises.current_city : "",
            current_postal: data.user.premises ? data.user.premises.current_postal : "",
            current_province: data.user.premises ? data.user.premises.current_province : "",
        });

        setSuretyInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            surety_name: data.user?.surety ? data.user?.surety.name : "",
            surety_surname: data.user?.surety ? data.user?.surety.surname : "",
            surety_cell: data.user?.surety ? "+" + data.user?.surety.cell : "",
            surety_home_number: data.user?.surety ? "+" + data.user?.surety.home_number : "",
            surety_work_number: data.user?.surety ? "+" + data.user?.surety.work_number : "",
            surety_fax_number: data.user?.surety ? "+" + data.user?.surety.fax_number : "",
            surety_email: data.user?.surety ? data.user?.surety.email : "",
            surety_address: data.user?.surety ? data.user?.surety.address : "",
            surety_id_number: data.user?.surety ? data.user?.surety.id_number : "",
            surety_date_of_birth: data.user?.surety ? data.user?.surety.date_of_birth : "",
            surety_gender: data.user?.surety ? data.user?.surety.gender : "",
            surety_marital_status: data.user?.surety ? data.user?.surety.marital_status : "",
        });

        setCompanyInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            company_name: data.user.profile && data.user.profile.company_name !== null ? data.user.profile.company_name : "",
            nature_of_business: data.user.profile && data.user.profile.nature_of_business !== null ? data.user.profile.nature_of_business : "",
            years_trading: data.user.profile && data.user.profile.years_trading !== null ? data.user.profile.years_trading : "",
            company_reg_no: data.user.profile && data.user.profile.company_reg_no !== null ? data.user.profile.company_reg_no : "",
            company_vat_no: data.user.profile && data.user.profile.company_vat_no !== null ? data.user.profile.company_vat_no : "",
            company_address: data.user.profile && data.user.profile.company_address !== null ? data.user.profile.company_address : "",
            company_type: data.user.profile && data.user.profile.company_type !== null ? data.user.profile.company_type : "",
            signage_name: data.user.profile && data.user.profile.signage_name !== null ? data.user.profile.signage_name : "",
            banking_bank: data.user.profile && data.user.profile.banking_bank !== null ? data.user.profile.banking_bank : "",
            banking_account_type: data.user.profile && data.user.profile.banking_account_type !== null ? data.user.profile.banking_account_type : "",
            banking_account_number: data.user.profile && data.user.profile.banking_account_number !== null ? data.user.profile.banking_account_number : "",
            banking_account_holder: data.user.profile && data.user.profile.banking_account_holder !== null ? data.user.profile.banking_account_holder : "",
        });

        setPremisesInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            property_id: data.property_id,
            unit_id: data.unit_id,
            size_required: data.user.premises ? data.user.premises.size_required : "",
        });

        setLeasingInitialValues({
            url: data?.edit_link !== null ? data?.edit_link?.edit_url : data.user.edit_url,
            lease_term: data.rates[data.rates.length - 1].lease_term ? data.rates[data.rates.length - 1].lease_term : 0,
            monthly_rental: data.rates[data.rates.length - 1].monthly_rental ? data.rates[data.rates.length - 1].monthly_rental : "",
            landlord_contrib: data.rates[data.rates.length - 1].landlord_contrib ? data.rates[data.rates.length - 1].landlord_contrib : 0,
            landlord_contrib_terms: data.rates[data.rates.length - 1].landlord_terms_contrib ? data.rates[data.rates.length - 1].landlord_terms_contrib : "",
            escalation: data.rates[data.rates.length - 1].escalation ? data.rates[data.rates.length - 1].escalation : "",
            deposit_type: data.rates[data.rates.length - 1].deposit_type ? data.rates[data.rates.length - 1].deposit_type : 0,
            occupation_date: data.rates[data.rates.length - 1].occupation_date ? data.rates[data.rates.length - 1].occupation_date : "",
            special_conditions: data.rates[data.rates.length - 1].special_conditions ? data.rates[data.rates.length - 1].special_conditions : "",
            flexi_months: data.rates[data.rates.length - 1].flexi_months ? data.rates[data.rates.length - 1].flexi_months : 0,
        });

        setSelectedLeaseTerm(data.rates[data.rates.length - 1].lease_term ? data.rates[data.rates.length - 1].lease_term : 0);
    }

    if (loading === true) {
        return <AppLoader />;
    }

    async function fetchAudit() {
        setAuditLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}log/${user_id}`,
        })
            .then((response) => {
                setLog(response.data);
            })
            .finally(() => {
                setAuditLoading(false);
            });
    }

    async function fetchNotes() {
        setNotesLoading(true);
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}legacy_notes/${current_booking.id}`,
        })
            .then((response) => {
                setNotes(response.data);
                // console.log(response.data);
            })
            .finally(() => {
                setNotesLoading(false);
            });
    }

    function formatIcon(status) {
        let icon = faCirclePlus;

        switch (status) {
            case "deleted":
                icon = faTrashCircle;
                break;
            case "updated":
                icon = faPenCircle;
                break;
            default:
                break;
        }

        return icon;
    }

    function formatIconClass(status) {
        let icon_class = " text-green-600";

        switch (status) {
            case "deleted":
                icon_class = "text-red-600";
                break;
            case "updated":
                icon_class = "text-yellow-500";
                break;
            default:
                break;
        }

        return icon_class;
    }

    // make audit entry clear to read
    function formatLogBooleans(value) {
        switch (value) {
            case "0":
            case 0:
            case false:
                return "False";
            case "1":
            case 1:
            case true:
                return "True";
            case "":
            case null:
                return "N/A";
            default:
                return value;
        }
    }

    function formatLogModel(model) {
        let value = model.split("\\");
        return value[value.length - 1];
    }

    function formatLogTitle(log) {
        if (formatLogModel(log.auditable_type) === "File" && log.new_values["name"]) {
            return "(" + log.new_values["name"] + ")";
        }
    }

    return (
        <>
            <div className="px-1 sm:px-4 my-2 xl:my-0 xl:p-4 h-screen xl:flex w-full">
                <div className="divide-y divide-gray-200 max-h-76 overflow-y-scroll xl:max-h-full xl:grid xl:grid-cols-12 xl:divide-y-0 xl:divide-x">
                    <Tab.Group>
                        <Tab.List className="bg-light_background xl:col-span-2 rounded-3xl w-full xl:w-72 border-0">
                            <div className="h-14 flex justify-evenly rounded-t-3xl items-center bg-dark_background">
                                <Link
                                    className="text-md leading-6 text-white"
                                    to={{
                                        pathname: "/booking/" + current_booking.location.toLowerCase().replace(/\s/g, ""),
                                        search: "?user_id=" + current_booking.user?.id + "&booking_id=" + current_booking.id,
                                        state: { from: "editUser", userUid: current_booking.user.id },
                                    }}
                                >
                                    <FontAwesomeIcon className="mr-4" icon={regular("arrow-left-long")} />
                                </Link>
                                <h1 className="text-xl leading-6 font-light text-white capitalize">Editing {current_booking.user.name + " " + current_booking.user.surname}</h1>
                                <FontAwesomeIcon className="mr-4 text-white" icon={regular("trash")} />
                            </div>
                            {navigation.map((item) => (
                                <Tab
                                    key={item.name}
                                    className={({ selected }) =>
                                        classNames(
                                            "group w-full flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none transition ease-linear delay-10",
                                            selected ? "border-l-primary bg-gray-100" : "bg-light_background border-l-light_background"
                                        )
                                    }
                                >
                                    <span className="truncate">{item.name}</span>
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels className="my-6 xl:my-0 xl:ml-28 2xl:ml-4 rounded-3xl bg-gray-100 xl:col-span-9">
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background ">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Signatory Details</h1>
                                </div>
                                <Formik
                                    initialValues={profileInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        //format cell
                                        values.cell = values.cell && values.cell.replace("+", "");
                                        values.home_number = values.home_number && values.home_number.replace("+", "");
                                        values.work_number = values.work_number && values.work_number.replace("+", "");
                                        values.fax_number = values.fax_number && values.fax_number.replace("+", "");

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: values.name,
                                            surname: values.surname,
                                            email: values.email,
                                            cell: values.cell,
                                            home_number: values.home_number,
                                            work_number: values.work_number,
                                            fax_number: values.fax_number,
                                            nationality: values.nationality,
                                            id_number: values.id_number,
                                            current_address: values.current_address,
                                            current_suburb: values.current_suburb,
                                            current_city: values.current_city,
                                            current_postal: values.current_postal,
                                            current_province: values.current_province,
                                            date_of_birth: values.date_of_birth,
                                            gender: values.gender,
                                            marital_status: values.marital_status,
                                            address_info: 1,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then((resp) => {
                                                if (resp.data && resp.data.length > 0 && resp.data[0] == "error") {
                                                    toast(<NotificationToast title="Something went wrong" message={resp.data[1]} type="error" />);
                                                } else {
                                                    toast(
                                                        <NotificationToast
                                                            title={current_booking.user.name + " " + current_booking.user.surname + " updated"}
                                                            message={"Your changes have been saved."}
                                                        />
                                                    );
                                                }
                                                getUser();

                                                //Add the + back for form prettiness
                                                values.cell = "+" + values.cell;
                                                values.home_number = "+" + values.home_number;
                                                values.work_number = "+" + values.work_number;
                                                values.fax_number = "+" + values.fax_number;
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their details such as name and email address.</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <Field name="name" type="text" as={SettingsTextInput} label="Name" />
                                                    <Field name="surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    <Field name="email" type="email" as={SettingsTextInput} label="Email" />
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Cell</span>
                                                            {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("cell", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="cell"
                                                                value={values.cell}
                                                            />
                                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Home</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("home_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="home_number"
                                                                value={values.home_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="work_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Work</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("work_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="work_number"
                                                                value={values.work_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                        <label htmlFor="fax_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                            <span>Fax</span>
                                                        </label>
                                                        <div className="mt-1">
                                                            <PhoneInput
                                                                defaultCountry="ZA"
                                                                countryCallingCodeEditable={false}
                                                                onChange={(val) => {
                                                                    setFieldValue("fax_number", val);
                                                                }}
                                                                onBlur={setFieldTouched}
                                                                name="fax_number"
                                                                value={values.fax_number}
                                                            />
                                                        </div>
                                                    </div>
                                                    <SettingsSelect
                                                        label="Nationality"
                                                        name="nationality"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.nationality}
                                                        touched={touched.nationality}
                                                        error={errors.nationality}
                                                        options="nationality"
                                                    />
                                                    <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" nationality={values.nationality} />

                                                    <Field
                                                        name="date_of_birth"
                                                        type="text"
                                                        as={SettingsDatePicker}
                                                        label="Date of Birth"
                                                        id_number={values.id_number}
                                                        nationality={values.nationality}
                                                        required
                                                    />
                                                    {errors.date_of_birth && (
                                                        <div className="text-orange-500 col-span-2 text-sm font-light">
                                                            <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                            We require the <span className="font-bold italic">{errors.date_of_birth}</span>
                                                        </div>
                                                    )}

                                                    <SettingsSelect
                                                        label="Gender"
                                                        name="gender"
                                                        required
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.gender}
                                                        touched={touched.gender}
                                                        error={errors.gender}
                                                        options={genders}
                                                    />

                                                    <SettingsSelect
                                                        label="Marital Status"
                                                        name="marital_status"
                                                        required
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.marital_status}
                                                        touched={touched.marital_status}
                                                        error={errors.marital_status}
                                                        options={mStatus}
                                                    />
                                                </div>

                                                <div className="grid grid-cols-6 gap-6 mt-24">
                                                    <Field name="current_address" type="text" as={SettingsTextInput} label="Street Address" />
                                                    <Field name="current_suburb" type="text" as={SettingsTextInput} label="Street Address Line 2" />
                                                    <Field name="current_city" type="text" as={SettingsTextInput} label="City" />
                                                    <SettingsSelect
                                                        label="Province"
                                                        name="current_province"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.current_province}
                                                        touched={touched.current_province}
                                                        error={errors.current_province}
                                                        options="provinces"
                                                    />
                                                    <Field name="current_postal" type="text" as={SettingsTextInput} label="Postal Code" />
                                                </div>
                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Joint Application */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Company Info</h1>
                                </div>
                                <Formik
                                    initialValues={companyInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        await Object.keys(bankList).forEach((key) => {
                                            if (values.banking_bank == bankList[key].label) {
                                                values.banking_bank_code = bankList[key].code;
                                            }
                                        });

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: profileInitialValues.name,
                                            cell: profileInitialValues.cell,
                                            id_number: profileInitialValues.id_number,
                                            email: profileInitialValues.email,
                                            date_of_birth: profileInitialValues.date_of_birth,
                                            company_name: values.company_name,
                                            nature_of_business: values.nature_of_business,
                                            years_trading: values.years_trading,
                                            company_reg_no: values.company_reg_no,
                                            company_vat_no: values.company_vat_no,
                                            company_address: values.company_address,
                                            company_type: values.company_type,
                                            signage_name: values.signage_name,
                                            banking_bank: values.banking_bank,
                                            banking_account_type: values.banking_account_type,
                                            banking_account_number: values.banking_account_number,
                                            banking_account_holder: values.banking_account_holder,
                                            banking_bank_code: values.banking_bank_code,
                                            company_info: 1,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then(() => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their Company Information.</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <div className="col-span-6">
                                                        <Field name="company_name" type="text" as={SettingsTextInput} label="Name of CC or Company" required />
                                                        {errors.company_name && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.company_name}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="company_reg_no" type="text" as={SettingsTextInput} label="Registration Number" required />
                                                        {errors.company_reg_no && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.company_reg_no}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="nature_of_business" type="text" as={SettingsTextInput} label="Nature of Business" required />
                                                        {errors.nature_of_business && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.nature_of_business}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="years_trading" type="text" as={SettingsTextInput} label="Number of years trading" required />
                                                        {errors.years_trading && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.years_trading}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="company_vat_no" type="text" as={SettingsTextInput} label="Vat Number" required />
                                                        {errors.company_vat_no && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.company_vat_no}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-6">
                                                        <Field name="company_address" type="text" as={SettingsTextInput} label="Current / Previous address" required />
                                                        {errors.company_address && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.company_address}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="company_type" type="text" as={SettingsTextInput} label="Company Type" required />
                                                        {errors.company_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.company_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="signage_name" type="text" as={SettingsTextInput} label="Name to appear as signage in building" required />
                                                        {errors.signage_name && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.signage_name}</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Bank"
                                                            name="banking_bank"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.banking_bank}
                                                            touched={touched.banking_bank}
                                                            error={errors.banking_bank}
                                                            options={bankList}
                                                            required
                                                        />
                                                        {errors.banking_bank && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_bank}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" required />
                                                        {errors.banking_account_type && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_type}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                        {errors.banking_account_number && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_number}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />
                                                        {errors.banking_account_holder && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.banking_account_holder}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Premises */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Premises Info</h1>
                                </div>
                                <Formik
                                    initialValues={premisesInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);
                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: profileInitialValues.name,
                                            cell: profileInitialValues.cell,
                                            id_number: profileInitialValues.id_number,
                                            email: profileInitialValues.email,
                                            date_of_birth: profileInitialValues.date_of_birth,
                                            property_id: values.property_id,
                                            unit_id: values.unit_id,
                                            size_required: values.size_required,
                                            property_info: 1,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then(() => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the applicant and contains their details such as Building and Unit.</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Building"
                                                        name="property_id"
                                                        required
                                                        getUnits={getUnits}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.property_id}
                                                        touched={touched.property_id}
                                                        error={errors.property_id}
                                                        options={properties}
                                                    />
                                                    <SettingsSelect
                                                        label="Unit"
                                                        name="unit_id"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.unit_id}
                                                        touched={touched.unit_id}
                                                        error={errors.unit_id}
                                                        options={units.length > 0 ? units : "none"}
                                                    />
                                                    <Field name="size_required" type="number" as={SettingsTextInput} label="Size Required (SQM)" />
                                                </div>
                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Suretyship owner details */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Suretyship Owner Details</h1>
                                </div>
                                <Formik
                                    initialValues={suretyInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        values.surety_cell = values.surety_cell && values.surety_cell.replace("+", "");
                                        values.surety_home_number = values.surety_home_number && values.surety_home_number.replace("+", "");
                                        values.surety_work_number = values.surety_work_number && values.surety_work_number.replace("+", "");
                                        values.surety_fax_number = values.surety_fax_number && values.surety_fax_number.replace("+", "");

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: profileInitialValues.name,
                                            cell: profileInitialValues.cell,
                                            id_number: profileInitialValues.id_number,
                                            email: profileInitialValues.email,
                                            date_of_birth: profileInitialValues.date_of_birth,
                                            surety_name: values.surety_name,
                                            surety_surname: values.surety_surname,
                                            surety_cell: values.surety_cell,
                                            surety_home_number: values.surety_home_number,
                                            surety_work_number: values.surety_work_number,
                                            surety_fax_number: values.surety_fax_number,
                                            surety_email: values.surety_email,
                                            surety_address: values.surety_address,
                                            surety_id_number: values.surety_id_number,
                                            surety_date_of_birth: values.surety_date_of_birth,
                                            surety_gender: values.surety_gender,
                                            surety_marital_status: values.surety_marital_status,
                                            surety_info: 1,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then(() => {
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the Surety.</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <div className="col-span-3">
                                                        <Field name="surety_name" type="text" as={SettingsTextInput} label="Name" />
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="surety_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="surety_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Cell</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("surety_cell", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="surety_cell"
                                                                    value={values.surety_cell}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="surety_home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Home</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("surety_home_number", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="surety_home_number"
                                                                    value={values.surety_home_number}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="surety_work_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Work</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("surety_work_number", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="surety_work_number"
                                                                    value={values.surety_work_number}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="surety_fax_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Fax</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("surety_fax_number", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="surety_fax_number"
                                                                    value={values.surety_fax_number}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-span-6">
                                                        <Field name="surety_email" type="surety_email" as={SettingsTextInput} label="Email" />
                                                    </div>

                                                    <div className="col-span-6">
                                                        <Field name="surety_address" type="text" as={SettingsTextInput} label="Residential Address" />
                                                    </div>

                                                    <div className="col-span-3">
                                                        <Field name="surety_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" />
                                                    </div>

                                                    <div className="col-span-3">
                                                        <Field name="surety_date_of_birth" type="text" as={SettingsDatePicker} label="Date of Birth" />
                                                    </div>

                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Gender"
                                                            name="surety_gender"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.surety_gender}
                                                            touched={touched.surety_gender}
                                                            error={errors.surety_gender}
                                                            options={genders}
                                                        />
                                                    </div>

                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Marital Status"
                                                            name="surety_marital_status"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.surety_marital_status}
                                                            touched={touched.surety_marital_status}
                                                            error={errors.surety_marital_status}
                                                            options={mStatus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>
                            {/* Next of Kin */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Leasing Info</h1>
                                </div>
                                <Formik
                                    initialValues={leasingInitialValues}
                                    enableReinitialize="true"
                                    onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                        setSubmitting(true);

                                        let submitData = {
                                            user: current_staff,
                                            url: url,
                                            name: profileInitialValues.name,
                                            cell: profileInitialValues.cell,
                                            id_number: profileInitialValues.id_number,
                                            email: profileInitialValues.email,
                                            date_of_birth: profileInitialValues.date_of_birth,
                                            lease_term: values.lease_term,
                                            monthly_rental: values.monthly_rental,
                                            landlord_contrib: values.landlord_contrib,
                                            landlord_contrib_terms: values.landlord_contrib_terms,
                                            escalation: values.escalation,
                                            deposit_type: values.deposit_type,
                                            occupation_date: values.occupation_date,
                                            special_conditions: values.special_conditions,
                                            flexi_months: values.flexi_months,
                                            lease_info: 1,
                                        };

                                        await axios({
                                            method: "put",
                                            url: `${process.env.REACT_APP_API_URL}user`,
                                            data: submitData,
                                        })
                                            .then((response) => {
                                                console.log(response);
                                                toast(
                                                    <NotificationToast title={current_booking.user.name + " " + current_booking.user.surname + " updated"} message={"Your changes have been saved."} />
                                                );
                                                getUser();
                                            })
                                            .catch((error) => {
                                                console.log(error.response);
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                                resetForm();
                                            });
                                    }}
                                >
                                    {({ values, touched, errors, setFieldValue, setFieldTouched, handleSubmit, isSubmitting, isValid, dirty }) => (
                                        <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4">
                                            <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                <p className="mt-1 text-sm text-gray-500">This information is related to the Lease</p>

                                                <div className="grid grid-cols-6 gap-6 mt-6">
                                                    <SettingsSelect
                                                        label="Lease Term"
                                                        name="lease_term"
                                                        contributions={contributions}
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.lease_term}
                                                        touched={touched.lease_term}
                                                        error={errors.lease_term}
                                                        options={leaseTerms}
                                                    />
                                                    <Field name="monthly_rental" type="text" as={SettingsTextInput} label="Monthly Rental (Ex Vat)" prepend="R" currency="true" />

                                                    {selectedLeaseTerm != 1 ? (
                                                        <>
                                                            <div className="col-span-3">
                                                                <SettingsSelect
                                                                    label="Which Landlord Contribution Would you prefer?"
                                                                    name="landlord_contrib"
                                                                    onChange={setFieldValue}
                                                                    onBlur={setFieldTouched}
                                                                    value={values.landlord_contrib}
                                                                    touched={touched.landlord_contrib}
                                                                    error={errors.landlord_contrib}
                                                                    options={landContrib}
                                                                />
                                                                <small>Can Only choose one of these options</small>
                                                            </div>
                                                            <div className="col-span-3">
                                                                <Field name="landlord_contrib_terms" type="text" as={SettingsTextInput} label="Terms of Landlord Contribution" />
                                                                <small>BO = How Many Months / TI = Rand Value</small>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className="col-span-3">
                                                            <SettingsSelect
                                                                label="How Many Flexi Months?"
                                                                name="flexi_months"
                                                                onChange={setFieldValue}
                                                                onBlur={setFieldTouched}
                                                                value={values.flexi_months}
                                                                touched={touched.flexi_months}
                                                                error={errors.flexi_months}
                                                                options={flexiList}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="col-span-3">
                                                        <Field name="escalation" type="text" as={SettingsTextInput} label="Escalation" prepend="%" currency="true" />
                                                        <small>Suggested Percentage</small>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Deposit"
                                                            name="deposit_type"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.deposit_type}
                                                            touched={touched.deposit_type}
                                                            error={errors.deposit_type}
                                                            options={depositList}
                                                        />
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="occupation_date" type="date" as={SettingsTextInput} label="Date of Occupation" required />
                                                        <small>Date</small>
                                                        {errors.occupation_date && (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                We require the <span className="font-bold italic">{errors.occupation_date}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-span-6">
                                                        <Field name="special_conditions" field_type="textarea" as={SettingsTextInput} label="Special Conditions" />
                                                    </div>
                                                </div>

                                                <div className="mt-8">
                                                    <SubmitButton
                                                        name="Save"
                                                        disabled={!isValid || isSubmitting || !dirty}
                                                        isSubmitting={isSubmitting}
                                                        icon={regular("save")}
                                                        text="Save"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Tab.Panel>

                            {/* Audit Log */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Audit Log</h1>
                                    <FontAwesomeIcon
                                        icon={regular("sync")}
                                        className={classNames(auditloading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                        onClick={() => fetchAudit()}
                                    />
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <ul role="list" className="space-y-6">
                                        {log.length &&
                                            log.map((l, index) => {
                                                return (
                                                    <li key={l.id}>
                                                        <div className="relative pb-8">
                                                            {index !== log.length - 1 ? <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> : null}
                                                            <div className="relative flex space-x-3">
                                                                <FontAwesomeIcon icon={formatIcon(l.event)} className={`${formatIconClass(l.event)} + h-8 w-8`} />
                                                                <div className="flex-1 space-y-1">
                                                                    <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                        <p className="text-md text-gray-900 font-medium">
                                                                            <span className="">
                                                                                {l.user ? `${l.user.name} ${l.user.surname}` : `${current_booking.user.name} ${current_booking.user.surname}`}
                                                                                {" " + l.event + " " + formatLogModel(l.auditable_type) + " "}-
                                                                            </span>
                                                                            <span className="text-md text-gray-600">
                                                                                {" "}
                                                                                {l.auditable_id} {formatLogTitle(l)}
                                                                            </span>
                                                                        </p>
                                                                        <p>{moment(l.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                    </div>
                                                                    <div className="flex ">
                                                                        <div className="w-1/2">
                                                                            {l.old_values && l.event === "updated" && (
                                                                                <>
                                                                                    <p className="font-medium">From</p>
                                                                                    <div className="flex">
                                                                                        <ul>
                                                                                            {Object.keys(l.old_values).map((key) => (
                                                                                                <li key={key} className="font-light capitalize">
                                                                                                    {key} -
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                        <ul>
                                                                                            {Object.values(l.old_values).map((value) => (
                                                                                                <li key={value} className="font-light ml-2 capitalize">
                                                                                                    {" "}
                                                                                                    {formatLogBooleans(value)}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="w-1/2">
                                                                            {l.new_values && l.event === "updated" && (
                                                                                <>
                                                                                    <p className="font-medium">To</p>
                                                                                    <div className="flex">
                                                                                        <ul>
                                                                                            {Object.keys(l.new_values).map((key) => (
                                                                                                <li key={key} className="font-light capitalize">
                                                                                                    {key} -
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                        <ul>
                                                                                            {Object.values(l.new_values).map((value) => (
                                                                                                <li key={value} className="font-light ml-2 capitalize">
                                                                                                    {" "}
                                                                                                    {formatLogBooleans(value)}
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </Tab.Panel>
                            {/* Notes */}
                            <Tab.Panel>
                                <div className="h-14 flex rounded-t-3xl items-center justify-center bg-dark_background">
                                    <h1 className="text-xl leading-6 font-light text-white capitalize">Notes</h1>
                                    <FontAwesomeIcon
                                        icon={regular("sync")}
                                        className={classNames(notesLoading ? "animate-spin" : "", "ml-6 leading-6 text-white cursor-pointer")}
                                        onClick={() => fetchNotes()}
                                    />
                                </div>
                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                    <ul role="list" className="space-y-6">
                                        {notes.length &&
                                            notes.map((n, index) => {
                                                return (
                                                    <li key={n.id}>
                                                        <div className="relative pb-8">
                                                            <div className="relative flex space-x-3">
                                                                <div className="flex-1 space-y-1">
                                                                    <div className="flex items-center justify-between border-b-2 border-gray-200">
                                                                        <div className="text-md text-gray-900 font-medium">
                                                                            {n.user[0]?.name != undefined ? n.user[0]?.name : "" + " " + n.user[0]?.surname != undefined ? n.user[0]?.surname : ""}
                                                                        </div>
                                                                        <p>{moment(n.created_at).format("DD/MM/YYYY H:m:ss")}</p>
                                                                    </div>
                                                                    <div className="flex ">{n.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </>
    );
}
