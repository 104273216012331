import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
// import SettingsDatePicker from "../Forms/SettingsDatePicker";
import SettingsSwitch from "../Forms/SettingsSwitch";
import SubmitButton from "../Forms/SubmitButton";
// import SettingsCounter from "../Forms/SettingsCounter";
import AppLoader from "../Helpers/AppLoader";
// import InputMask from "react-input-mask";
import PhoneInput from "react-phone-number-input";

import { faUser, faHouse, faBuilding, faFileContract, faShield } from "@fortawesome/pro-regular-svg-icons";
// import FileUpload from "../Forms/FileUpload";
// import FileDisplaySimple from "../Forms/FileDisplaySimple";
// import { getBooking } from "../../store/booking/bookingActions";
import CustomButton from "../Helpers/CustomButton";
import QPDocumentUpload from "./QPDocumentUpload";
import SettingsDatePicker from "../Forms/SettingsDatePicker";
import { format } from "date-fns";

const subNavigation = [
    { name: "Signatory Details", icon: faUser },
    { name: "Company Info", icon: faBuilding },
    { name: "Premises Info", icon: faHouse },
    { name: "Suretyship Owner Details", icon: faShield },
    { name: "Leasing Info", icon: faFileContract },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ApplicationFormQP() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    // const [fileUploadInformation, setFileUploadInformation] = useState([]);
    // const [unitTypes, setUnitTypes] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    // const [initialValues, setInitialValues] = useState({lease_surance: 1});
    const [properties, setProperties] = useState([]);
    // const { current_booking } = useSelector((state) => state.booking);
    const [current_booking, setCurrentBooking] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [bankList, setBankList] = useState([]);
    const [units, setUnits] = useState([]);

    const [selectedLeaseTerm, setSelectedLeaseTerm] = useState("");

    const leaseTerms = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi Lease" },
        { value: 2, label: "1 Year" },
        { value: 3, label: "2 Years" },
        { value: 4, label: "3 Years" },
        { value: 5, label: "4 Years" },
        { value: 6, label: "5+ Years" },
    ];

    const landContrib = [
        { value: 0, label: "" },
        { value: 1, label: "Beneficial Occupation" },
        { value: 2, label: "Tenant Installation" },
    ];

    const flexiList = [
        { value: 0, label: "" },
        { value: 1, label: "Month to Month" },
        { value: 2, label: "1 Month" },
        { value: 3, label: "2 Months" },
        { value: 4, label: "3 Months" },
        { value: 5, label: "4 Months" },
        { value: 6, label: "5 Months" },
        { value: 7, label: "6 Months" },
        { value: 8, label: "7 Months" },
        { value: 9, label: "8 Months" },
        { value: 10, label: "9 Months" },
        { value: 11, label: "10 Months" },
        { value: 12, label: "11 Months" },
    ];

    const depositList = [
        { value: 0, label: "" },
        { value: 1, label: "Flexi / 1 Year Lease = 1 Month Rent" },
        { value: 2, label: "2+ Years Lease = 2 Months Exit Rent" },
    ];

    const genders = [
        { label: "", value: 0 },
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
    ];

    const mStatus = [
        { label: "", value: "" },
        { label: "Single", value: 1 },
        { label: "Married", value: 2 },
        { label: "Widowed", value: 3 },
        { label: "Divorced", value: 4 },
    ];

    useEffect(async () => {
        setLoading(true);
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}properties`,
        })
            .then((response) => {
                setProperties(response.data);
            })
            .catch((error) => {
                alert("properties failed");
            });

        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}paystack_banks`,
        })
            .then((response) => {
                let banks = response.data.data;
                let bankList = [];

                Object.keys(banks).forEach((key) => {
                    bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                });

                setBankList(bankList);
            })
            .catch((error) => {
                alert("Bank failed");
            });

        if (url) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);

                    // getUnitTypes(response.data.property_id);

                    setInitialValues({
                        //signatory details
                        url: url,
                        name: response.data.user.name,
                        surname: response.data.user.surname !== null ? response.data.user.surname : "",
                        nationality: response.data.user.profile && response.data.user.profile.nationality !== null ? response.data.user.profile.nationality : "",
                        id_number: response.data.user.profile && response.data.user.profile.id_number !== null ? response.data.user.profile.id_number : "",
                        cell: response.data.user.cell !== "" ? "+" + response.data.user.cell : "",
                        home_number: response.data.user.profile && response.data.user.profile.home_number ? "+" + response.data.user.profile.home_number : "",
                        work_number: response.data.user.profile && response.data.user.profile.work_number ? "+" + response.data.user.profile.work_number : "",
                        fax_number: response.data.user.profile && response.data.user.profile.fax_number ? "+" + response.data.user.profile.fax_number : "",
                        current_address: response.data.user.premises ? response.data.user.premises.current_address : "",
                        current_suburb: response.data.user.premises ? response.data.user.premises.current_suburb : "",
                        current_city: response.data.user.premises ? response.data.user.premises.current_city : "",
                        current_postal: response.data.user.premises ? response.data.user.premises.current_postal : "",
                        current_province: response.data.user.premises ? response.data.user.premises.current_province : "",
                        email: response.data.user.email,
                        date_of_birth: response.data.user.profile && response.data.user.profile.date_of_birth !== null ? response.data.user.profile.date_of_birth : "",
                        gender: response.data.user.profile && response.data.user.profile.gender !== null ? response.data.user.profile.gender : 0,
                        marital_status: response.data.user.profile && response.data.user.profile.marital_status !== null ? response.data.user.profile.marital_status : 0,

                        //surety owner details

                        surety_name: response.data.user?.surety ? response.data.user.surety.name : "",
                        surety_surname: response.data.user?.surety ? response.data.user.surety.surname : "",
                        surety_cell: response.data.user?.surety ? "+" + response.data.user.surety.cell : "",
                        surety_home_number: response.data.user?.surety ? "+" + response.data.user.surety.home_number : "",
                        surety_work_number: response.data.user?.surety ? "+" + response.data.user.surety.work_number : "",
                        surety_fax_number: response.data.user?.surety ? "+" + response.data.user.surety.fax_number : "",
                        surety_email: response.data.user?.surety ? response.data.user.surety.email : "",
                        surety_address: response.data.user?.surety ? response.data.user.surety.address : "",
                        surety_id_number: response.data.user?.surety ? response.data.user.surety.id_number : "",
                        surety_date_of_birth: response.data.user?.surety ? response.data.user.surety.date_of_birth : "",
                        surety_gender: response.data.user?.surety ? response.data.user.surety.gender : "",
                        surety_marital_status: response.data.user?.surety ? response.data.user.surety.marital_status : "",

                        //company info
                        company_name: response.data.user.profile && response.data.user.profile.company_name !== null ? response.data.user.profile.company_name : "",
                        nature_of_business: response.data.user.profile && response.data.user.profile.nature_of_business !== null ? response.data.user.profile.nature_of_business : "",
                        years_trading: response.data.user.profile && response.data.user.profile.years_trading !== null ? response.data.user.profile.years_trading : "",
                        company_reg_no: response.data.user.profile && response.data.user.profile.company_reg_no !== null ? response.data.user.profile.company_reg_no : "",
                        company_vat_no: response.data.user.profile && response.data.user.profile.company_vat_no !== null ? response.data.user.profile.company_vat_no : "",
                        company_address: response.data.user.profile && response.data.user.profile.company_address !== null ? response.data.user.profile.company_address : "",
                        company_type: response.data.user.profile && response.data.user.profile.company_type !== null ? response.data.user.profile.company_type : "",
                        signage_name: response.data.user.profile && response.data.user.profile.signage_name !== null ? response.data.user.profile.signage_name : "",
                        banking_bank: response.data.user.profile && response.data.user.profile.banking_bank !== null ? response.data.user.profile.banking_bank : "",
                        banking_account_type: response.data.user.profile && response.data.user.profile.banking_account_type !== null ? response.data.user.profile.banking_account_type : "",
                        banking_account_number: response.data.user.profile && response.data.user.profile.banking_account_number !== null ? response.data.user.profile.banking_account_number : "",
                        banking_account_holder: response.data.user.profile && response.data.user.profile.banking_account_holder !== null ? response.data.user.profile.banking_account_holder : "",

                        //presmises info
                        property_id: response.data.property_id ? response.data.property_id : "",
                        unit_id: response.data.unit_id ? response.data.unit_id : "",
                        size_required: response.data.user.premises ? response.data.user.premises.size_required : "",

                        //leaseing info
                        lease_term: response.data.rates[response.data.rates.length - 1].lease_term ? response.data.rates[response.data.rates.length - 1].lease_term : 0,
                        monthly_rental: response.data.rates[response.data.rates.length - 1].monthly_rental ? response.data.rates[response.data.rates.length - 1].monthly_rental : "",
                        landlord_contrib: response.data.rates[response.data.rates.length - 1].landlord_contrib ? response.data.rates[response.data.rates.length - 1].landlord_contrib : 0,
                        landlord_contrib_terms: response.data.rates[response.data.rates.length - 1].landlord_terms_contrib
                            ? response.data.rates[response.data.rates.length - 1].landlord_terms_contrib
                            : "",
                        escalation: response.data.rates[response.data.rates.length - 1].escalation ? response.data.rates[response.data.rates.length - 1].escalation : "",
                        deposit_type: response.data.rates[response.data.rates.length - 1].deposit_type ? response.data.rates[response.data.rates.length - 1].deposit_type : 0,
                        occupation_date: response.data.rates[response.data.rates.length - 1].occupation_date ? response.data.rates[response.data.rates.length - 1].occupation_date : "",
                        special_conditions: response.data.rates[response.data.rates.length - 1].special_conditions ? response.data.rates[response.data.rates.length - 1].special_conditions : "",
                        flexi_months: response.data.rates[response.data.rates.length - 1].flexi_months ? response.data.rates[response.data.rates.length - 1].flexi_months : 0,
                    });

                    setSelectedLeaseTerm(response.data.rates[response.data.rates.length - 1].lease_term ? response.data.rates[response.data.rates.length - 1].lease_term : 0);
                    if (response.data.property_id != null) {
                        getUnits(response.data.property_id);
                    }

                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setInitialValues({
                //signatory details
                name: "",
                surname: "",
                nationality: "",
                id_number: "",
                cell: "",
                home_number: "",
                work_number: "",
                fax_number: "",
                current_address: "",
                current_suburb: "",
                current_city: "",
                current_postal: "",
                current_province: "",
                email: "",
                date_of_birth: "",
                gender: 0,
                marital_status: 0,

                //surety
                surety_name: "",
                surety_surname: "",
                surety_cell: "",
                surety_home_number: "",
                surety_work_number: "",
                surety_fax_number: "",
                surety_email: "",
                surety_address: "",
                surety_id_number: "",
                surety_date_of_birth: "",
                surety_gender: "",
                surety_marital_status: "",

                //company info
                company_name: "",
                nature_of_business: "",
                years_trading: "",
                company_reg_no: "",
                company_vat_no: "",
                company_address: "",
                company_type: "",
                signage_name: "",
                banking_bank: "",
                banking_account_type: "",
                banking_account_number: "",
                banking_account_holder: "",

                //presmises info
                property_id: "",
                unit_id: "",
                size_required: "",
                broker_cell: "",

                //leaseing info
                lease_term: 0,
                monthly_rental: "",
                landlord_contrib: 0,
                landlord_contrib_terms: "",
                escalation: "",
                deposit_type: 0,
                occupation_date: "",
                special_conditions: "",
                flexi_months: 0,
            });

            setLoading(false);
        }
    }, [setProperties, setInitialValues]);

    async function getUnits(id) {
        await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}units/${id}`,
        }).then((response) => {
            setUnits(response.data);
        });
    }

    if (loading === true) return <AppLoader />;

    // async function getUnitTypes(id) {
    //     await axios({
    //         method: "get",
    //         url: `${process.env.REACT_APP_API_URL}unit_types/${id}`,
    //     }).then((response) => {
    //         setUnitTypes(response.data);
    //     });
    // }

    async function contributions(id) {
        setSelectedLeaseTerm(id);
    }

    function formatToFloat(value) {
        if (value) {
            return parseFloat(value.replace(/,/g, ""));
        } else {
            return value;
        }
    }

    function availableIncome(income, outcome) {
        var money_in = formatToFloat(income);
        var money_out = formatToFloat(outcome);

        return money_in - money_out;
    }

    if (
        current_booking &&
        current_booking.rates &&
        current_booking.rates.length > 0 &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== "" &&
        current_booking.rates[current_booking.rates.length - 1].signed_lease_at !== null
    ) {
        return (
            <main className="mt-14">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <h2>Your lease has been signed, no futher updates to your information are allowed.</h2>
                </div>
            </main>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize="true"
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
                name: Yup.string().required("name for Signatory Details"),
                nationality: Yup.string().required("nationality for Signatory Details"),
                id_number: Yup.string().required("ID or passport number for Signatory Details"),
                cell: Yup.number().required("phone number for Signatory Details"),
                current_address: Yup.string().required("street address for Signatory Details"),
                current_suburb: Yup.string().required("street address line 2 for Signatory Details"),
                current_city: Yup.string().required("city for Signatory Details"),
                current_postal: Yup.string().required("postal code for Signatory Details"),
                current_province: Yup.string().required("province for Signatory Details"),
                email: Yup.string().required("email for Signatory Details"),
                date_of_birth: Yup.string().required("Date of birth for Signatory Details"),
                gender: Yup.number().required("Gender for Signatory Details"),
                marital_status: Yup.number().required("Marital Status for Signatory Details"),

                company_name: Yup.string().required("company name for Company Info"),
                nature_of_business: Yup.string().required("Nature of Business for Company Info"),
                years_trading: Yup.number().required("Number of years trading for premises Info"),
                company_reg_no: Yup.string().required("company registration number for Company Info"),
                company_vat_no: Yup.string().required("company VAT number for Company Info"),
                company_address: Yup.string().required("Address for Company Info"),
                company_type: Yup.string().required("company type for Company Info"),
                signage_name: Yup.string().required("Signage Name for Company Info"),
                banking_bank: Yup.string().required("Bank Name for Company Info"),
                banking_account_type: Yup.string().required("Bank Name for Company Info"),
                banking_account_number: Yup.string().required("Account Number for Company Info"),
                banking_account_holder: Yup.string().required("Account Holder for Company Info"),

                surety_name: Yup.string().required("Surety Name for Suretyship Owner Details"),
                surety_surname: Yup.string().required("Surety Surname for Suretyship Owner Details"),
                surety_cell: Yup.number().required("Surety Cell for Suretyship Owner Details"),
                surety_email: Yup.string().required("Surety Email for Suretyship Owner Details"),
                surety_id_number: Yup.string().required("Surety ID Number for Suretyship Owner Details"),
                surety_marital_status: Yup.number().required("Surety Marital Status for Suretyship Owner Details"),

                size_required: Yup.number().required("size required for premises Info"),

                occupation_date: Yup.string().required("occupation date for Leasing Info"),

                popi: Yup.number().required("confirmation to the POPI Act").min(1, "confirmation to the POPI Act"),
                legal: Yup.number().positive("confirmation that your information is legally correct").min(1, "confirmation that your information is legally correct"),
                tpn: Yup.number().positive("confirmation that a Credit Check will be run").min(1, "confirmation that a Credit Check will be run"),
            })}
            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                setSubmitting(true);

                //format cell
                values.cell = values.cell && values.cell.replace("+", "");
                values.broker_cell = values.broker_cell && values.broker_cell.replace("+", "");
                values.home_number = values.home_number && values.home_number.replace("+", "");
                values.work_number = values.work_number && values.work_number.replace("+", "");
                values.fax_number = values.fax_number && values.fax_number.replace("+", "");
                values.surety_cell = values.surety_cell && values.surety_cell.replace("+", "");
                values.surety_home_number = values.surety_home_number && values.surety_home_number.replace("+", "");
                values.surety_work_number = values.surety_work_number && values.surety_work_number.replace("+", "");
                values.surety_fax_number = values.surety_fax_number && values.surety_fax_number.replace("+", "");

                await Object.keys(bankList).forEach((key) => {
                    if (values.banking_bank == bankList[key].label) {
                        values.banking_bank_code = bankList[key].code;
                    }
                });

                values.date_of_birth = format(new Date(values.date_of_birth), "yyyy-MM-dd");
                values.surety_date_of_birth = format(new Date(values.surety_date_of_birth), "yyyy-MM-dd");

                var formData = new FormData();

                //signatory data
                formData.append("name", values.name);
                formData.append("surname", values.surname);
                formData.append("nationality", values.nationality);
                formData.append("id_number", values.id_number);
                formData.append("cell", values.cell);
                formData.append("home_number", values.home_number);
                formData.append("work_number", values.work_number);
                formData.append("fax_number", values.fax_number);
                formData.append("current_address", values.current_address);
                formData.append("current_suburb", values.current_suburb);
                formData.append("current_city", values.current_city);
                formData.append("current_province", values.current_province);
                formData.append("current_postal", values.current_postal);
                formData.append("email", values.email);
                formData.append("date_of_birth", values.date_of_birth);
                formData.append("gender", values.gender);
                formData.append("marital_status", values.marital_status);

                //surety owner details
                formData.append("surety_name", values.surety_name);
                formData.append("surety_surname", values.surety_surname);
                formData.append("surety_cell", values.surety_cell);
                formData.append("surety_home_number", values.surety_home_number);
                formData.append("surety_work_number", values.surety_work_number);
                formData.append("surety_fax_number", values.surety_fax_number);
                formData.append("surety_email", values.surety_email);
                formData.append("surety_address", values.surety_address);
                formData.append("surety_id_number", values.surety_id_number);
                formData.append("surety_date_of_birth", values.surety_date_of_birth);
                formData.append("surety_gender", values.surety_gender);
                formData.append("surety_marital_status", values.surety_marital_status);

                //Company Info
                formData.append("company_name", values.company_name);
                formData.append("nature_of_business", values.nature_of_business);
                formData.append("years_trading", values.years_trading);
                formData.append("company_reg_no", values.company_reg_no);
                formData.append("company_vat_no", values.company_vat_no);
                formData.append("company_address", values.company_address);
                formData.append("company_type", values.company_type);
                formData.append("signage_name", values.signage_name);
                formData.append("banking_bank", values.banking_bank);
                formData.append("banking_account_type", values.banking_account_type);
                formData.append("banking_account_number", values.banking_account_number);
                formData.append("banking_account_holder", values.banking_account_holder);
                formData.append("banking_bank_code", values.banking_bank_code);

                //Premises Info
                formData.append("property_id", values.property_id);
                formData.append("unit_id", values.unit_id);
                formData.append("size_required", values.size_required);
                if (!url) {
                    formData.append("broker_cell", values.broker_cell);
                }

                //leasing info
                formData.append("lease_term", values.lease_term);
                formData.append("monthly_rental", values.monthly_rental);
                formData.append("landlord_contrib", values.landlord_contrib);
                formData.append("landlord_contrib_terms", values.landlord_contrib_terms);
                formData.append("flexi_months", values.flexi_months);
                formData.append("escalation", values.escalation);
                formData.append("deposit_type", values.deposit_type);
                formData.append("occupation_date", values.occupation_date);
                formData.append("special_conditions", values.special_conditions);

                formData.append("edit_form", 1);

                //Files
                if (values.copy_id_file) {
                    var idFile = document.querySelector("#copy_id_file");
                    formData.append("copy_id_file", idFile.files[0]);
                }
                if (values.company_reg_docs_file) {
                    var companyReg = document.querySelector("#company_reg_docs_file");
                    formData.append("company_reg_docs_file", companyReg.files[0]);
                }
                if (values.bank_statements_file) {
                    var bankState = document.querySelector("#bank_statements_file");
                    formData.append("bank_statements_file", bankState.files[0]);
                }

                let data = JSON.parse(JSON.stringify(values));
                let api_method = "post";
                if (url) {
                    // api_method = "put";
                    formData.append("url", url);
                    formData.append("_method", "PUT");
                }

                await axios({
                    method: api_method,
                    url: `${process.env.REACT_APP_API_URL}user`,
                    data: formData,
                    headers: { "content-type": "multipart/form-data" },
                })
                    .then((response) => {
                        if (url) {
                            toast(
                                <NotificationToast
                                    title={"Application Updated"}
                                    message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                        } else {
                            toast(
                                <NotificationToast
                                    title={"Application Successful. Redirecting to Edit form"}
                                    message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                />
                            );
                            setTimeout(() => {
                                window.location = window.location.origin + "/edit_application/" + response.data;
                            }, 3000);
                        }

                        // Add the + back for form prettiness
                        values.cell = "+" + values.cell;
                        values.broker_cell = "+" + values.broker_cell;
                        values.home_number = "+" + values.home_number;
                        values.work_number = "+" + values.work_number;
                        values.fax_number = "+" + values.fax_number;
                        values.surety_cell = "+" + values.surety_cell;
                        values.surety_home_number = "+" + values.surety_home_number;
                        values.surety_work_number = "+" + values.surety_work_number;
                        values.surety_fax_number = "+" + values.surety_fax_number;
                    })
                    .catch((response) => {
                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                    });
            }}
        >
            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                        <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                            <div className="lg:flex">
                                <img className="h-24" src="/images/logo-qholdings-v2.png" alt="Quorum Properties" />
                                <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                                    <h1 className="text-3xl text-white">
                                        Welcome {url && "back"} to Quorum Properties {url && current_booking && current_booking.user && current_booking.user.name + " " + current_booking.user.surname}
                                    </h1>
                                    {url ? (
                                        <p className="text-gray-200 mt-4">
                                            Update your information at each <span className="italic font-bold">Section</span> and update your application by clicking{" "}
                                            <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    ) : (
                                        <p className="text-gray-200 mt-4">
                                            Complete the form below by filling in all the required information at each <span className="italic font-bold">Section</span> and start your application by
                                            clicking <span className="italic font-bold">Submit Application</span>.
                                        </p>
                                    )}
                                    {Object.keys(errors).length > 0 && (
                                        <>
                                            <p className="mt-6 text-red-500 text-lg font-bold">Cannot apply!</p>{" "}
                                            <p className="my-2 text-red-500 text-sm">Please make sure you fill in all sections of the form below:</p>
                                        </>
                                    )}
                                    {Object.keys(errors).length < 1 && (
                                        <div className="px-8">
                                            <SubmitButton
                                                name="Save"
                                                disabled={!isValid || isSubmitting || !dirty}
                                                isSubmitting={isSubmitting}
                                                text={url ? "Update Application" : "Submit Application"}
                                                style={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                    <main className="-mt-32">
                        <div className="max-w-7xl mx-auto pb-2 px-4">
                            <div className="space-y-8 divide-y divide-gray-200">
                                <div className="w-full">
                                    <div>
                                        <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Signatory Details</h1>
                                        </div>
                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 bg-gray-100">
                                            <div className="col-span-3">
                                                <Field name="name" type="text" as={SettingsTextInput} label="Name" required />
                                                {errors.name && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.name}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="surname" type="text" as={SettingsTextInput} label="Surname" />
                                            </div>

                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Nationality"
                                                    name="nationality"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.nationality}
                                                    touched={touched.nationality}
                                                    error={errors.nationality}
                                                    options="nationality"
                                                    required
                                                />
                                                {errors.nationality && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.nationality}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required nationality={values.nationality} />
                                                {errors.id_number && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.id_number}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {/* <Field name="cell" type="text" as={SettingsTextInput} prepend="+" label="Cell" required /> */}
                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Cell</span>
                                                        {errors.cell && <p className="text-xs font-light text-red-400">required</p>}
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("cell", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="cell"
                                                            value={values.cell}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                {errors.cell && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.cell}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Home</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("home_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="home_number"
                                                            value={values.home_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="work_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Work</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("work_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="work_number"
                                                            value={values.work_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="fax_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Fax</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("fax_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="fax_number"
                                                            value={values.fax_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-6">
                                                <Field name="email" type="email" as={SettingsTextInput} label="Email" required />
                                                {errors.email && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.email}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                <Field
                                                    name="date_of_birth"
                                                    type="text"
                                                    as={SettingsDatePicker}
                                                    label="Date of Birth"
                                                    id_number={values.id_number}
                                                    nationality={values.nationality}
                                                    required
                                                />
                                                {errors.date_of_birth && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.date_of_birth}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Gender"
                                                    name="gender"
                                                    required
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.gender}
                                                    touched={touched.gender}
                                                    error={errors.gender}
                                                    options={genders}
                                                />
                                            </div>

                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Marital Status"
                                                    name="marital_status"
                                                    required
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.marital_status}
                                                    touched={touched.marital_status}
                                                    error={errors.marital_status}
                                                    options={mStatus}
                                                />
                                            </div>

                                            <div className="col-span-6">
                                                <Field name="current_address" type="text" as={SettingsTextInput} label="Street Address" required />
                                                {errors.current_address && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.current_address}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-6">
                                                <Field name="current_suburb" type="text" as={SettingsTextInput} label="Street Address Line 2" required />
                                                {errors.current_suburb && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.current_suburb}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="current_city" type="text" as={SettingsTextInput} label="City" required />
                                                {errors.current_city && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.current_city}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Province"
                                                    name="current_province"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.current_province}
                                                    touched={touched.current_province}
                                                    error={errors.current_province}
                                                    options="provinces"
                                                    required
                                                />
                                                {errors.current_province && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.current_province}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="current_postal" type="text" as={SettingsTextInput} label="Postal Code" required />
                                                {errors.current_postal && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.current_postal}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* {!(
                                            errors.name ||
                                            errors.nationality ||
                                            errors.id_number ||
                                            errors.cell ||
                                            errors.current_address ||
                                            errors.current_suburb ||
                                            errors.current_city ||
                                            errors.current_province ||
                                            errors.current_postal ||
                                            errors.email ||
                                            errors.date_of_birth ||
                                            errors.gender ||
                                            errors.marital_status
                                        ) ? (
                                            <div className="flex justify-around">
                                                <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                            </div>
                                        ) : (
                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                            </div>
                                        )} */}

                                        <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background mt-6">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Company Info</h1>
                                        </div>

                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 bg-gray-100">
                                            <div className="col-span-6">
                                                <Field name="company_name" type="text" as={SettingsTextInput} label="Name of CC or Company" required />
                                                {errors.company_name && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.company_name}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="company_reg_no" type="text" as={SettingsTextInput} label="Registration Number" required />
                                                {errors.company_reg_no && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.company_reg_no}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="nature_of_business" type="text" as={SettingsTextInput} label="Nature of Business" required />
                                                {errors.nature_of_business && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.nature_of_business}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="years_trading" type="number" as={SettingsTextInput} label="Number of years trading" required />
                                                {errors.years_trading && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.years_trading}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="company_vat_no" type="text" as={SettingsTextInput} label="Vat Number" required />
                                                {errors.company_vat_no && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.company_vat_no}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-6">
                                                <Field name="company_address" type="text" as={SettingsTextInput} label="Current / Previous address" required />
                                                {errors.company_address && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.company_address}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="company_type" type="text" as={SettingsTextInput} label="Company Type" required />
                                                {errors.company_type && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.company_type}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="signage_name" type="text" as={SettingsTextInput} label="Name to appear as signage in building" required />
                                                {errors.signage_name && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.signage_name}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                {/* <Field name="banking_bank" type="text" as={SettingsTextInput} label="Bank" required /> */}
                                                <SettingsSelect
                                                    label="Bank"
                                                    name="banking_bank"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.banking_bank}
                                                    touched={touched.banking_bank}
                                                    error={errors.banking_bank}
                                                    options={bankList}
                                                    required
                                                />
                                                {errors.banking_bank && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.banking_bank}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" required />
                                                {errors.banking_account_type && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.banking_account_type}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {/* <div className="col-span-3">
                                                            <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" required />
                                                            {errors.banking_branch && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                    We require the <span className="font-bold italic">{errors.banking_branch}</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="col-span-3">
                                                            <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" required />
                                                            {errors.banking_branch_code && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                    We require the <span className="font-bold italic">{errors.banking_branch_code}</span>
                                                                </div>
                                                            )}
                                                        </div> */}
                                            <div className="col-span-3">
                                                <Field name="banking_account_number" type="text" as={SettingsTextInput} label="Account Number" required />
                                                {errors.banking_account_number && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.banking_account_number}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="banking_account_holder" type="text" as={SettingsTextInput} label="Account Holder" required />
                                                {errors.banking_account_holder && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.banking_account_holder}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="flex justify-around">
                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} />
                                            {!(
                                                errors.company_name ||
                                                errors.company_reg_no ||
                                                errors.company_vat_no ||
                                                errors.company_type ||
                                                errors.nature_of_business ||
                                                errors.years_trading ||
                                                errors.company_address ||
                                                errors.signage_name ||
                                                errors.banking_bank ||
                                                errors.banking_account_type ||
                                                errors.banking_account_number ||
                                                errors.banking_account_holder
                                            ) ? (
                                                <div className="flex justify-around">
                                                    <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                                </div>
                                            ) : (
                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                    <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                </div>
                                            )}
                                        </div> */}

                                        <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background mt-6">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Premises Info</h1>
                                        </div>

                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 bg-gray-100">
                                            {" "}
                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Building Name (Optional)"
                                                    name="property_id"
                                                    getUnits={getUnits}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.property_id}
                                                    touched={touched.property_id}
                                                    error={errors.property_id}
                                                    options={properties}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Unit Number (Optional)"
                                                    name="unit_id"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.unit_id}
                                                    touched={touched.unit_id}
                                                    error={errors.unit_id}
                                                    options={units}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="size_required" type="number" as={SettingsTextInput} label="Size Required (SQM)" required />
                                                {errors.size_required && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.size_required}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {!url ? (
                                                <>
                                                    <div className="col-span-3">
                                                        <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                            <label htmlFor="cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                                <span>Broker (If yes, please provide your cell number)</span>
                                                            </label>
                                                            <div className="mt-1">
                                                                <PhoneInput
                                                                    defaultCountry="ZA"
                                                                    international
                                                                    countryCallingCodeEditable={false}
                                                                    onChange={(val) => {
                                                                        setFieldValue("broker_cell", val);
                                                                    }}
                                                                    onBlur={setFieldTouched}
                                                                    name="broker_cell"
                                                                    value={values.broker_cell}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        {/* <div className="flex justify-around">
                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} />
                                            {!errors.size_required ? (
                                                <div className="flex justify-around">
                                                    <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                                </div>
                                            ) : (
                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                    <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                </div>
                                            )}
                                        </div> */}

                                        <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background mt-6">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Suretyship Owner Details</h1>
                                        </div>

                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 bg-gray-100">
                                            <div className="col-span-3">
                                                <Field name="surety_name" type="text" as={SettingsTextInput} label="Name" />
                                                {errors.surety_name && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_name}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="surety_surname" type="text" as={SettingsTextInput} label="Surname" />
                                                {errors.surety_surname && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_surname}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="surety_cell" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Cell</span>
                                                        {/* {errors.surety_cell && <p className="text-xs font-light text-red-400">required</p>} */}
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("surety_cell", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="surety_cell"
                                                            value={values.surety_cell}
                                                            // required
                                                        />
                                                    </div>
                                                </div>
                                                {errors.surety_cell && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_cell}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="surety_home_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Home</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("surety_home_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="surety_home_number"
                                                            value={values.surety_home_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="surety_work_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Work</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("surety_work_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="surety_work_number"
                                                            value={values.surety_work_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-3">
                                                <div className="col-span-2 sm:col-span-3 transition ease-linear delay-5">
                                                    <label htmlFor="surety_fax_number" className="text-sm font-medium mb-2 text-gray-700 flex items-baseline justify-between">
                                                        <span>Fax</span>
                                                    </label>
                                                    <div className="mt-1">
                                                        <PhoneInput
                                                            defaultCountry="ZA"
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            onChange={(val) => {
                                                                setFieldValue("surety_fax_number", val);
                                                            }}
                                                            onBlur={setFieldTouched}
                                                            name="surety_fax_number"
                                                            value={values.surety_fax_number}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-6">
                                                <Field name="surety_email" type="surety_email" as={SettingsTextInput} label="Email" />
                                                {errors.surety_email && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_email}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-6">
                                                <Field name="surety_address" type="text" as={SettingsTextInput} label="Residential Address" />
                                                {/* {errors.surety_address && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                    We require the <span className="font-bold italic">{errors.surety_address}</span>
                                                                </div>
                                                            )} */}
                                            </div>

                                            <div className="col-span-3">
                                                <Field name="surety_id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" />
                                                {errors.surety_id_number && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_id_number}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-span-3">
                                                <Field
                                                    name="surety_date_of_birth"
                                                    type="text"
                                                    as={SettingsDatePicker}
                                                    label="Date of Birth"
                                                    // id_number={values.id_number}
                                                    // nationality={values.nationality}
                                                    // required
                                                />
                                                {/* {errors.surety_date_of_birth && (
                                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                    We require the <span className="font-bold italic">{errors.surety_date_of_birth}</span>
                                                                </div>
                                                            )} */}
                                            </div>

                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Gender"
                                                    name="surety_gender"
                                                    // required
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.surety_gender}
                                                    touched={touched.surety_gender}
                                                    error={errors.surety_gender}
                                                    options={genders}
                                                />
                                            </div>

                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Marital Status"
                                                    name="surety_marital_status"
                                                    required
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.surety_marital_status}
                                                    touched={touched.surety_marital_status}
                                                    error={errors.surety_marital_status}
                                                    options={mStatus}
                                                />
                                                {errors.surety_marital_status && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.surety_marital_status}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {/* <div className="flex justify-around">
                                            <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                        {/* {!(errors.company_name || errors.company_reg_no || errors.company_vat_no || errors.company_type ||errors.nature_of_business || errors.years_trading || errors.company_address || errors.signage_name || errors.banking_bank || errors.banking_account_type || errors.banking_account_number || errors.banking_account_holder ) ? ( */}
                                        {/* <div className="flex justify-around">
                                                <CustomButton text={"Next step"} onClick={() => setSelectedIndex(selectedIndex + 1)} />
                                            </div> */}
                                        {/* ) : (
                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                                <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                            </div>
                                                        )} */}
                                        {/* </div> */}

                                        <div className="h-14 flex items-center rounded-t-3xl justify-center bg-dark_background mt-6">
                                            <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">leasing Info</h1>
                                        </div>

                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 bg-gray-100">
                                            {" "}
                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Lease Term"
                                                    name="lease_term"
                                                    contributions={contributions}
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.lease_term}
                                                    touched={touched.lease_term}
                                                    error={errors.lease_term}
                                                    options={leaseTerms}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="monthly_rental" type="text" as={SettingsTextInput} label="Monthly Rental (Ex Vat)" prepend="R" currency="true" />
                                            </div>
                                            {selectedLeaseTerm != 1 ? (
                                                <>
                                                    <div className="col-span-3">
                                                        <SettingsSelect
                                                            label="Which Landlord Contribution Would you prefer?"
                                                            name="landlord_contrib"
                                                            onChange={setFieldValue}
                                                            onBlur={setFieldTouched}
                                                            value={values.landlord_contrib}
                                                            touched={touched.landlord_contrib}
                                                            error={errors.landlord_contrib}
                                                            options={landContrib}
                                                        />
                                                        <small>Can Only choose one of these options</small>
                                                    </div>
                                                    <div className="col-span-3">
                                                        <Field name="landlord_contrib_terms" type="text" as={SettingsTextInput} label="Terms of Landlord Contribution" />
                                                        <small>BO = How Many Months / TI = Rand Value</small>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="col-span-3">
                                                    <SettingsSelect
                                                        label="How Many Flexi Months?"
                                                        name="flexi_months"
                                                        onChange={setFieldValue}
                                                        onBlur={setFieldTouched}
                                                        value={values.flexi_months}
                                                        touched={touched.flexi_months}
                                                        error={errors.flexi_months}
                                                        options={flexiList}
                                                    />
                                                </div>
                                            )}
                                            <div className="col-span-3">
                                                <Field name="escalation" type="text" as={SettingsTextInput} label="Escalation" prepend="%" currency="true" />
                                                <small>Suggested Percentage</small>
                                            </div>
                                            <div className="col-span-3">
                                                <SettingsSelect
                                                    label="Deposit"
                                                    name="deposit_type"
                                                    onChange={setFieldValue}
                                                    onBlur={setFieldTouched}
                                                    value={values.deposit_type}
                                                    touched={touched.deposit_type}
                                                    error={errors.deposit_type}
                                                    options={depositList}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <Field name="occupation_date" type="date" as={SettingsTextInput} label="Date of Occupation" required />
                                                <small>Date</small>
                                                {errors.occupation_date && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require the <span className="font-bold italic">{errors.occupation_date}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-6">
                                                <Field name="special_conditions" field_type="textarea" as={SettingsTextInput} label="Special Conditions" />
                                            </div>
                                            <div className="col-span-6">
                                                <Field
                                                    name="legal"
                                                    as={SettingsSwitch}
                                                    required
                                                    label="I confirm that the information provided is legally correct and binding in a court of law. The Applicant/s hereby warrant/s, represents and undertakes that any information provided to QPG Holdings Proprietary Limited (the 'Company') is true and accurate and it has not knowingly withheld information which, if disclosed, might affect the Company's decision in whether or not to agree to conclude a lease with the Applicant/s."
                                                />
                                                {errors.legal && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require <span className="font-bold italic">{errors.legal}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-6">
                                                <Field
                                                    name="popi"
                                                    as={SettingsSwitch}
                                                    required
                                                    label="I Agree to the POPI ACT"
                                                    description="Compliance with the Protection of Personal Information Act (POPIA), also known as the POPI Act, is mandatory for all organisations in South Africa. POPI makes it illegal to collect, use or store the personal information of consumers and businesses unless it is done in accordance with the laws and regulations prescribed in the Act. Privacy Notice:  DATA COLLECTED We collect the following data from our customers and suppliers: email addresses, names of contact persons, contact telephone numbers, information required for accounting and invoicing purposes, country of operation, name of organisation or person from whom data is being collected, physical addresses for delivery purposes. DATA USAGE The data collected is mainly used in the core function of our business. It will also be used for communication with customers and suppliers, dealing with internal enquiries, and to advise our customers and suppliers as to the progress being made with requests received. The information collected is also utilised by us to respond to your enquiries made on our website. We need your data to send you information regarding our services as part of our legitimate interest in your enquiry. We need to communicate with you in this regard. We will not share your data with any other third parties or use your data for any purpose other than described above. The information collected by us that you provide will not be used to make any automated decisions about you. Your data will also be used for correspondence or reports that will be sent to you concerning our services using your name and email address."
                                                />
                                                {errors.popi && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require <span className="font-bold italic">{errors.popi}</span>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-span-6">
                                                <Field
                                                    name="tpn"
                                                    as={SettingsSwitch}
                                                    required
                                                    label="I Agree to the Credit Check"
                                                    description="By ticking this box, I hereby agree and acknowledge that QPG Holdings Proprietary Limited may, via any one or more registered credit bureaus, preform any credit search on my credit profile; monitor my payment profile; use any new information or data on any future credit applications; and/or record whether the account is being conducted in a satisfactory manner"
                                                />
                                                {errors.tpn && (
                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                        <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                        We require <span className="font-bold italic">{errors.tpn}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {!url ? (
                                                <>
                                                    <div className="col-span-3">
                                                        <label className="text-sm font-medium mb-1 text-gray-700 flex items-baseline justify-between">Copy of ID</label>
                                                        <input
                                                            type="file"
                                                            name="copy_id_file"
                                                            id="copy_id_file"
                                                            onChange={(event) => {
                                                                setFieldValue("copy_id_file", event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <br />
                                                        <small>PDF Preferred</small>
                                                    </div>

                                                    <div className="col-span-3">
                                                        <label className="text-sm font-medium mb-1 text-gray-700 flex items-baseline justify-between">Company Reg Docs</label>
                                                        <input
                                                            type="file"
                                                            name="company_reg_docs_file"
                                                            id="company_reg_docs_file"
                                                            onChange={(event) => {
                                                                setFieldValue("company_reg_docs_file", event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <br />
                                                        <small>PDF Preferred</small>
                                                    </div>

                                                    <div className="col-span-3">
                                                        <label className="text-sm font-medium mb-1 text-gray-700 flex items-baseline justify-between">3 Months Bank Statements</label>
                                                        <input
                                                            type="file"
                                                            name="bank_statements_file"
                                                            id="bank_statements_file"
                                                            onChange={(event) => {
                                                                setFieldValue("bank_statements_file", event.currentTarget.files[0]);
                                                            }}
                                                        />
                                                        <br />
                                                        <small>PDF Preferred</small>
                                                    </div>
                                                </>
                                            ) : (
                                                <QPDocumentUpload url={url} />
                                            )}
                                        </div>

                                        <div className="flex justify-around my-8">
                                            {/* <CustomButton text={"Previous step"} onClick={() => setSelectedIndex(selectedIndex - 1)} /> */}
                                            {!(errors.occupation_date || errors.popi) ? (
                                                Object.keys(errors).length < 1 && (
                                                    <div className="flex justify-around">
                                                        <SubmitButton
                                                            name="Save"
                                                            disabled={!isValid || isSubmitting || !dirty || Object.keys(errors).length < 1}
                                                            isSubmitting={isSubmitting}
                                                            text={url ? "Update Application" : "Submit Application"}
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <div className="text-orange-500 col-span-2 text-sm font-light">
                                                    <FontAwesomeIcon className=" mr-1" icon={regular("triangle-exclamation")} />
                                                    <span className="font-bold italic">Please fill in all the required information before moving to the next step.</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </Form>
            )}
        </Formik>
    );
}
